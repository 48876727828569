export default {
  textInfo: {
    title1: '关于我们',
    title2: '噪声控制工程',
    title3: '污水处理工程',
    title4: '大气环保工程',
    text1: `<p style="text-indent: 2em;">Langde GmbH 成立于2023年8月，位于排名世界宜居城市前列的奥地利维也纳。主营业务：分销和安装阳光房、结合光伏的阳光房、降噪设备等。针对有阳光房需求的用户，以功能性为基础，兼顾采光需求，亲近自然的同时适量利用清洁能源。适合有环保意识、钟爱清洁能源且兼有采光需求，自发减碳并具有一定经济基础的用户，应用场景为居民住宅、中小型工业及商业设施。合作伙伴来自于中国，以物美价廉的产品，打造一体化的技术解决方案，勤奋的我们，提供给您高效的全方位服务。</p>`
  },
  product: {
    title: '产品介绍',
    product1: '百叶吸声板',
    product2: '微孔吸声板',
    product3: '条孔高强水泥复合吸隔声屏障',
    product4: '大型工业用消声器',
    desc1:
      '<p style="text-indent: 2em;">户用分布式光伏系统，通过在民房、别墅等拥有独立产权的屋顶或居民闲置用地上，安装单晶硅太阳能发电电池板，将太阳能转化为电能。分布式光伏系统由太阳能电池板、逆变器、支架和辅材等四个部分组成，分布式光伏系统所发的电不仅可以满足家庭日常使用，还可以将剩余电量卖给国家电网获取收益，同时还可以享受政府补贴。</p>',
    desc2: '',
    desc3:
      '● 高强水泥复合吸隔声屏障是以水泥、多孔轻质集料、多种化学助剂和增强剂为主要原料，经过复合而成的空心板，其正面板具有一定的开孔率，由离心玻璃棉及公振结构复合而成的吸声主体，达到隔声吸声的功能。',
    desc4:
      '● 消声装置是一种既能允许气流顺利通过又能有效地阻止或减弱声能向外传播的装置。它是用来降低空气动力设备进排气口噪声或沿管道传播的噪声的有效方法之一。'
  },
  menus: {
    company: '朗德有限公司',
    home: '首页',
    introduce: '介绍',
    demo: '案例',
    contact: '联系我们'
  },
  desc: {
    scope: '业务范围',
    recruitment: '招聘信息',
    desc1: '行政人员1名',
    desc2: '业务员2人',
    info1: '节省能源，成为能源转型的一部分！',
    info2: '钢结构阳光房 / 铝合金结构阳光房 / 光伏',
    text1: '钢结构阳光房',
    text2: '铝合金结构阳光房',
    text3: '光伏'
  },
  demo: {
    title: '家用光伏电站',
    text1: '<p style="text-indent: 2em;">家用光伏电站主要是利用家庭现有建筑物上的闲置资源，如屋顶、墙体立面、阳台、院落等，安装和使用光伏发电系统，它能直接将太阳能转化成电能，一部分满足自家用电需求，节省电费。</p>'
  },
  demo2: {
    title: '污水处理工程',
    text1: '● 候平高速平路服务器 B 区污水处理设备更新工程',
    text2: '● 候马收费站生物转盘污水处理工程',
    text3: '● 陕西高速集团汉中管理所污水处理站工程',
    text4: '● 郑州至尧山高速公路服务器及收费站污水处理系统改造工程',
    text5: '● 大广高速公路深州至大名(翼豫界)段项目房建工程暂估价设备采购及安装工程',
    text6: '● 河北高速公路污水处理系统改造工程',
  },
  demo3: {
    title: '大气环保工程',
    text1: '● 唐山建龙钢铁有限公司烧结机烟气脱硫工程',
    text2: '● 沙钢集团永兴钢铁有限公司烧结烟气脱硫工程',
    text3: '● 新钢钢铁有限公司煤气回收工程',
    text4: '● 河北新武安钢铁集团鑫山钢铁有限公司煤气回收工程',
    text5: '● 扬州恒润重工有限公司湿法除尘工程',
    text6: '● 扬州恒润重工有限公司干法除尘工程',
    text7: '● 扬州恒润重工有限公司钢渣处理工程'
  }
}
