export default {
  textInfo: {
    title1: 'About Us',
    title2: 'Noise control engineering',
    title3: 'Sewage treatment project',
    title4: 'Atmospheric environmental protection project',
    text1: `<p style="text-indent: 2em;">Langde GmbH was founded in August 2023 and is located in Vienna, Austria, one of the world's most livable cities. Main business: distribution and installation of sunrooms, sunrooms combined with photovoltaics, and noise reduction equipment. For those who need a sunroom that is functional and light-intensive, close to nature while utilizing the right amount of clean energy. Suitable for users with environmental awareness, love of clean energy and lighting needs, spontaneous carbon reduction and a certain economic base, application scenarios for residential, small and medium-sized industrial and commercial facilities. Partners from China, with good quality and low price products, to create an integrated technical solution, we are diligent, to provide you with efficient all-round service!</p>`
  },
  product: {
    title: 'Product',
    product1: 'Louvered Acoustic panels',
    product2: 'Microporous Acoustic Panel',
    product3: 'Cement Acoustic Barrier',
    product4: 'Large industrial muffler',
    desc1: '<p style="text-indent: 2em;">Household distributed photovoltaic systems convert solar energy into electricity by installing monocrystalline silicon solar panels on the rooftops of private houses, villas, etc., which have independent property rights, or on the unused land of residents. Distributed photovoltaic system consists of solar panels, inverters, stents and auxiliary materials and other four parts of the distributed photovoltaic system of electricity can not only meet the daily use of the family, but also the surplus power can be sold to the national grid to obtain income, but also enjoy government subsidies. </p>',
    desc2: '',
    desc3: '● High-strength cement composite sound-absorbing barrier is made of cement, porous lightweight aggregate, a variety of chemical additives and reinforcing agents as the main raw materials, after the composite of the hollow plate, the front panel has a certain open pore rate, the centrifugal glass wool and the public vibration structure composite sound-absorbing body, to achieve acoustic sound insulation and sound absorption function.',
    desc4: '● An anechoic device is a device that allows the smooth passage of airflow while effectively preventing or attenuating the outward propagation of sound energy. It is one of the effective methods used to reduce the noise of air intake and exhaust ports of aerodynamic equipment or the noise propagated along ducts.',
  },
  menus: {
    company: 'langde GmbH',
    home: 'Home',
    introduce: 'Introduce',
    demo: 'Demo',
    contact: 'Contact'
  },
  desc: {
    scope: 'Business Scope',
    recruitment: 'Recruitment',
    desc1: '1 administrative staff',
    desc2: '2 salespersons',
    info1: 'Save energy and be part of the energy transition!',
    info2:
      'Steel structure sunroom / Aluminum alloy structure sunroom / Photovoltaic',
    text1: 'Steel structure sunroom',
    text2: 'Aluminum alloy structure sunroom',
    text3: 'Photovoltaic'
  },
  demo: {
    title: 'Residential photovoltaic power plant',
    text1: '<p style="text-indent: 2em;">Household photovoltaic power station is mainly the use of household existing buildings on the unused resources, such as roofs, wall facades, balconies, courtyards, etc., the installation and use of photovoltaic power generation system, which can be directly converted into solar energy into electricity, part of the home to meet the demand for electricity, saving electricity costs.</p>'
    // text2: '● Jiguang Expressway Jinan Connector Section Duandian Interchange to South Round City Line Sound Barrier Project',
    // text3: '● Pinggu District Early Bao Road Road Project - Sound Barrier Project',
    // text4: '● Retrofitting of noise barriers along part of Airport South Link',
    // text5: '● Beijing-Taiwan Expressway (Beijing Section) Environmental Protection Construction Bidding Section Sound Screen Project',
    // text6: '● Beijing-Kunming Expressway Sound Barrier Replacement Project',
    // text7: '● Installation of acoustic panels for maintenance of parts of the Fifth Ring Road',
  },
  demo2: {
    title: 'Sewage treatment project',
    text1:
      '● Renewal of sewage treatment equipment in Area B of Ping Road Server of Houping Expressway',
    text2:
      '● Sewage treatment project of biological interchange at Houma toll station',
    text3:
      '● Shaanxi Expressway Group Hanzhong Management Station Sewage Treatment Station Project',
    text4: '● Zhengzhou-Yaoshan Expressway Server and Toll Station Sewage Treatment System Renovation Project',
    text5:
      '● Provisional Estimated Equipment Procurement and Installation of Room Construction Project for Daguang Expressway Shenzhou-Daiming (Wing-Yu Boundary) Section Project',
    text6: '● Improvement Project of Sewage Treatment System of Hebei Expressway',
  },
  demo3: {
    title: 'Atmospheric Environmental Protection Project',
    text1: '● Sinter Flue Gas Desulphurization Project of Tangshan Jianlong Iron & Steel Co.',
    text2: '● Sintering Flue Gas Desulphurization Project of Shagang Group Yongxing Iron & Steel Co.',
    text3: '● Gas Recovery Project of Xinsteel Iron & Steel Co.',
    text4: '● Gas Recovery Project of Hebei Xinwuan Iron & Steel Group Xinshan Iron & Steel Co.',
    text5: '● Yangzhou Hengrun Heavy Industry Co.',
    text6: '● Yangzhou Hengrun Heavy Industry Co.',
    text7: '● Yangzhou Hengrun Heavy Industry Co.'
  }
}
