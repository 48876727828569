<template>
  <div class="home-content" ref="homeContent">
    <el-backtop
      target=".home-content"
      :bottom="100"
      :right="100"
      :visibility-height="80"
    />
    <div class="header">
      <el-row>
        <el-col :xs="20" :sm="20" :md="14" :lg="16" :xl="19">
          <div class="home-logo">
            <el-image src="https://s1.imagehub.cc/images/2023/12/10/0d752ce993e771048b13706cf50ecc94.png" />
            <div class="text">{{ $t('menus.company') }}</div>
          </div>
        </el-col>
        <el-col
          class="menu hidden-sm-and-down"
          :sm="12"
          :md="10"
          :lg="8"
          :xl="5"
        >
          <div class="home-menu">
            <div>
              <a href="javascript:void(0)" @click="goAuchor('#topSwiper')">
                {{ $t('menus.home') }}
              </a>
            </div>
            <div>
              <a href="javascript:void(0)" @click="goAuchor('#introduce')">
                {{ $t('menus.introduce') }}
              </a>
            </div>
            <div>
              <a href="javascript:void(0)" @click="goAuchor('#demo')">
                {{ $t('menus.demo') }}
              </a>
            </div>
            <div>
              <a href="javascript:void(0)" @click="goAuchor('#contact')">
                {{ $t('menus.contact') }}
              </a>
            </div>
            <el-dropdown trigger="click" @command="onCommand">
              <div>
                {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-item">
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-col>
        <el-col class="menu-btn hidden-sm-and-up" :xs="4" :sm="4">
          <div class="icon el-icon-s-fold" @click="drawer = true" />
        </el-col>
      </el-row>
    </div>
    <div class="body">
      <div class="top-swiper animate__animated animate__slideInDown" id="topSwiper">
        <el-carousel trigger="click">
          <el-carousel-item>
            <el-image fit="cover" src="https://s1.imagehub.cc/images/2023/12/10/63d7421f30f353b8ade6f5fea6adfd60.png" />
            <div class="desc">
              <div>{{ $t('desc.info1') }}</div>
              <div>{{ $t('desc.info2') }}</div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="info-content" id="introduce">
        <el-row>
          <el-col
            class="img-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="pic-content hidden-sm-and-down">
              <el-image
                class="info-img1"
                src="https://s1.imagehub.cc/images/2023/12/10/41359baadb3b5dc89451efaa077051f2.png"
              />
              <el-image
                class="info-img2"
                src="https://s1.imagehub.cc/images/2023/12/10/5ea57a8f9d67c355d329e9d7d2ed11d3.png"
              />
            </div>
            <div class="small-image">
              <el-image
                class="full-image hidden-sm-and-up"
                fit="cover"
                src="https://s1.imagehub.cc/images/2023/12/10/41359baadb3b5dc89451efaa077051f2.png"
              /><el-image
                class="full-image hidden-sm-and-up"
                fit="cover"
                src="https://s1.imagehub.cc/images/2023/12/10/5ea57a8f9d67c355d329e9d7d2ed11d3.png"
              />
            </div>
          </el-col>
          <el-col
            class="text-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="info">
              <div class="title">{{ $t('textInfo.title1') }}</div>
              <div class="text-body" v-html="$t('textInfo.text1')" />
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="info-content dark">
        <el-row>
          <el-col
            class="img-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="product-content">
              <div class="product-item">
                <el-image
                  class="info-img1"
                  src="https://s1.imagehub.cc/images/2023/12/12/7b10db89b2f7da07cabd7290dd290fcb.png"
                />
                <!-- <div class="product-text">{{ $t('product.product1') }}</div> -->
              </div>
              <div class="product-item">
                <el-image
                  class="info-img1"
                  src="https://s1.imagehub.cc/images/2023/12/12/e9d107e32f8b70187b1c5a10a349ab1a.jpeg"
                />
                <!-- <div class="product-text">{{ $t('product.product2') }}</div> -->
              </div>          
              <div class="product-item">
                <el-image
                  class="info-img1"
                  src="https://s1.imagehub.cc/images/2023/12/12/bfb536c6c9ba13cc94bb138e1a6fc509.png"
                />
                <!-- <div class="product-text">{{ $t('product.product4') }}</div> -->
              </div>
              <div class="product-item">
                <el-image
                  class="info-img1"
                  src="https://s1.imagehub.cc/images/2023/12/12/542d511b0e6cf3f181927a7de7207257.png"
                />
                <!-- <div class="product-text">{{ $t('product.product4') }}</div> -->
              </div>
            </div>
          </el-col>
          <el-col
            class="text-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="info">
              <div class="title">{{ $t('product.title') }}</div>
              <div class="text-body">
                <div class="text-item" v-html="$t('product.desc1')"></div>
                <!-- <div class="text-item" v-html="$t('product.desc3')"></div>
                <div class="text-item" v-html="$t('product.desc4')"></div> -->
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="demo-info" id="demo">{{ $t('menus.demo') }}</div>
      <div class="info-content">
        <el-row>
          <el-col
            class="img-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="demo-content">
              <el-image
                class="info-img1"
                src="https://s1.imagehub.cc/images/2023/12/12/923a9307fe2c28d679d3ae62cd46b974.jpeg"
              />
            </div>
          </el-col>
          <el-col
            class="text-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="info">
              <div class="title">{{ $t('demo.title') }}</div>
              <div class="text-body">
                <div class="text-item dark" v-html="$t('demo.text1')" />
                <!-- <div class="text-item dark">{{ $t('demo.text2') }}</div>
                <div class="text-item dark">{{ $t('demo.text3') }}</div>
                <div class="text-item dark">{{ $t('demo.text4') }}</div>
                <div class="text-item dark">{{ $t('demo.text5') }}</div>
                <div class="text-item dark">{{ $t('demo.text6') }}</div>
                <div class="text-item dark">{{ $t('demo.text7') }}</div> -->
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="info-content dark">
        <el-row>
          <el-col
            class="text-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="info">
              <div class="title">{{ $t('demo2.title') }}</div>
              <div class="text-body">
                <div class="text-item">{{ $t('demo2.text1') }}</div>
                <div class="text-item">{{ $t('demo2.text2') }}</div>
                <div class="text-item">{{ $t('demo2.text3') }}</div>
                <div class="text-item">{{ $t('demo2.text4') }}</div>
                <div class="text-item">{{ $t('demo2.text5') }}</div>
                <div class="text-item">{{ $t('demo2.text6') }}</div>
              </div>
            </div>
          </el-col>
          <el-col
            class="img-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="demo-content">
              <el-image
                class="info-img1"
                src="https://s1.imagehub.cc/images/2023/12/10/83b3a0a852b8ee7f5a23a7538f16c6fa.png"
              />
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="info-content">
        <el-row>
          <el-col
            class="img-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="demo-content">
              <el-image
                class="info-img1"
                src="https://s1.imagehub.cc/images/2023/12/10/e574ca8a49a00cbc71d3352e5ec1ceae.png"
              />
            </div>
          </el-col>
          <el-col
            class="text-content"
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
          >
            <div class="info">
              <div class="title">{{ $t('demo3.title') }}</div>
              <div class="text-body">
                <div class="text-item dark">{{ $t('demo3.text1') }}</div>
                <div class="text-item dark">{{ $t('demo3.text2') }}</div>
                <div class="text-item dark">{{ $t('demo3.text3') }}</div>
                <div class="text-item dark">{{ $t('demo3.text4') }}</div>
                <div class="text-item dark">{{ $t('demo3.text5') }}</div>
                <div class="text-item dark">{{ $t('demo3.text6') }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="footer" id="contact">
      <el-row>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="link">
            <div class="our-company" @click="$router.go(0)">
              <el-image src="https://s1.imagehub.cc/images/2023/12/10/0d752ce993e771048b13706cf50ecc94.png" />
              <div class="text">{{ $t('menus.company') }}</div>
            </div>
            <!-- <div class="other-company" @click="$router.go(0)">
              <el-image src="https://s1.imagehub.cc/images/2023/12/12/a462589ad1fe2ad59b39d077416b35a6.png" />
            </div> -->
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="contact">
            <div class="content">
              <div class="title">{{ $t('menus.contact') }}</div>
              <div class="contact-info">
                <div>{{ $t('menus.company') }}</div>
                <div>{{ 'office@langde.com' }}</div>
                <div>{{ 'Dittmanngasse 4 Top 1/52，1110 Wien.Austria' }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="contact">
            <div class="content">
              <div class="title">{{ $t('desc.scope') }}</div>
              <div class="contact-info">
                <div>{{ $t('desc.text1') }}</div>
                <div>{{ $t('desc.text2') }}</div>
                <div>{{ $t('desc.text3') }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
          <div class="contact">
            <div class="content">
              <div class="title">{{ $t('desc.recruitment') }}</div>
              <div class="contact-info">
                <div>{{ $t('desc.desc1') }}</div>
                <div>{{ $t('desc.desc2') }}</div>
              </div>
            </div>
          </div>
        </el-col>
        <div class="bottom">@ 2023 langde GmbH</div>
      </el-row>
    </div>
    <el-drawer
      custom-class="drawer"
      title=""
      :visible.sync="drawer"
      direction="ltr"
      size="90%"
    >
      <div class="info-item" @click="goAuchor('#topSwiper')">
        <a href="javascript:void(0)">
          {{ $t('menus.home') }}
        </a>
      </div>
      <div class="info-item" @click="goAuchor('#introduce')">
        <a href="javascript:void(0)">
          {{ $t('menus.introduce') }}
        </a>
      </div>
      <div class="info-item" @click="goAuchor('#demo')">
        <a href="javascript:void(0)">
          {{ $t('menus.demo') }}
        </a>
      </div>
      <div class="info-item" @click="goAuchor('#contact')">
        <a href="javascript:void(0)">
          {{ $t('menus.contact') }}
        </a>
      </div>
      <div class="info-item">
        <el-dropdown trigger="click" @command="onCommand">
          <div>
            {{ language }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown-item">
            <el-dropdown-item command="zh">中文</el-dropdown-item>
            <el-dropdown-item command="en">English</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="info-item">+43 677 61504598</div>
    </el-drawer>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      language: '中文',
      drawer: false
    }
  },
  methods: {
    onCommand(cmd) {
      localStorage.setItem('language', cmd)
      this.$forceUpdate()
      if (localStorage.getItem('language')) {
        this.$i18n.locale = localStorage.getItem('language')
      } else {
        this.$i18n.locale = cmd
      }
      if (cmd === 'zh') {
        this.language = '中文'
        document.title = '朗德有限公司'
      } else {
        this.language = 'English'
        document.title = 'langde GmbH'
      }
    },
    goAuchor(id) {
      document.querySelector(id).scrollIntoView(true)
      var auchor = this.$el.querySelector(id)
      document.documentElement.scrollTop = auchor.offsetTop - 60
    }
  },
  mounted() {
    if (localStorage.getItem('language')) {
      this.$i18n.locale = localStorage.getItem('language')
      if (localStorage.getItem('language') === 'zh') {
        this.language = '中文'
        document.title = '朗德有限公司'
      } else {
        this.language = 'English'
        document.title = 'langde GmbH'
      }
    }
    // this.$i18n.locale = 'en'
    // console.log(this.$i18n.locate = 'en')
  }
}
</script>
<style lang="scss">
.dropdown-item {
  li {
    // color: #33a061;
    color: #101218;
    &:hover {
      // color: #33a061 !important;
      // background: rgba(0, 136, 58, 0.2) !important;
      color: #101218 !important;
      background: rgba(16, 18, 24, 0.2) !important;
    }
  }
}
</style>
<style lang="scss" scoped>
:deep(.drawer) {
  .el-drawer__header {
    margin: 0;
    padding: 30px;
    border-bottom: 1px solid #ebeef5;
  }
  .el-drawer__body {
    padding: 0 30px 30px 30px;
    .info-item {
      padding: 20px;
      font-size: 32px;
      border-bottom: 1px solid #ebeef5;
      &:hover {
        color: #2fc1ff;
      }
      & a {
        color: #101218;
      }
      .el-dropdown {
        font-size: 32px;
        color: #101218;
      }
    }
  }
}
.home-content {
  width: 100%;
  height: calc(100vh - 120px);
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 120px;
    line-height: 120px;
    z-index: 999;
    overflow: hidden;
    // background: rgba(0, 136, 58, 0.8);
    background: rgba(16, 18, 24, 0.8);
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    .home-logo {
      display: flex;
      align-items: center;
      height: 120px;
      color: #ffffff;
      font-family: 'Helvetica';
      .el-image {
        padding: 0 20px;
        width: 80px;
        height: 80px;
      }
    }
    .menu {
      :deep(.home-menu) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        white-space: nowrap;
        color: #ffffff;
        .el-dropdown {
          color: #ffffff;
          font-size: 37px;
        }
      }
    }
    .menu-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 120px;
      .icon {
        padding: 10px;
        border-radius: 5px;
        background: #2fc1ff;
        color: #ffffff;
        font-size: 45px;
      }
    }
  }
  .body {
    margin-top: 120px;
    .top-swiper {
      :deep(.el-carousel) {
        .el-carousel__container {
          height: calc(100vh - 120px);
          position: relative;
          .el-image {
            width: 100%;
            height: calc(100vh - 120px);
          }
          .desc {
            position: absolute;
            width: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 120px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }
        }
      }
    }
    .info-content {
      min-height: calc(100vh - 120px);
      display: flex;
      align-items: center;
      &.dark {
        background: #4c5253;
      }
      .el-row {
        width: 100%;
      }
      .img-content {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // min-height: calc(100vh - 120px);
        // overflow: hidden;
        .small-image {
          display: flex;
          flex-direction: column;
          .full-image {
            width: 100%;
          }
        }
        .demo-content {
          padding: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          .el-image {
            width: 80%;
            height: 80%;
            padding: 40px;
            border-radius: 40px;
            overflow: hidden;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            background: #ffffff;
          }
        }
        .product-content {
          width: 80%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          .el-image {
            width: 420px;
            height: 300px;
            margin: 60px;
            border-radius: 40px;
            overflow: hidden;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
          .product-text {
            color: #ffffff;
            font-size: 36px;
            font-weight: bold;
            text-align: center;
          }
        }
        .pic-content {
          width: 80%;
          height: 800px;
          position: relative;
          .el-image {
            border-radius: 40px;
            overflow: hidden;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          }
          .info-img1 {
            top: 0;
            left: 0;
            width: 80%;
            height: 80%;
            position: absolute;
          }
          .info-img2 {
            right: 0;
            bottom: 0;
            width: 80%;
            height: 80%;
            position: absolute;
          }
        }
      }
      .text-content {
        height: 100%;
        padding: 60px;
        .info {
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // flex-direction: column;
        }
        .title {
          align-self: flex-start;
          color: #2fc1ff;
          font-weight: bold;
          font-size: 60px;
        }
        .text-body {
          margin-top: 40px;
          font-size: 40px;
          line-height: 60px;
          .text-item {
            color: #ffffff;
            font-weight: bold;
            font-size: 38px;
            line-height: 80px;
            &.dark {
              color: #101218;
            }
          }
        }
      }
    }
    .demo-info {
      padding: 60px;
      font-size: 60px;
      background: #ffffff;
      color: #2fc1ff;
      text-align: center;
      font-weight: bold;
    }
  }
  .footer {
    padding: 60px;
    color: #ffffff;
    font-size: 32px;
    line-height: 60px;
    background: #101218;
    .el-col {
      height: 20vh;
    }
    .link {
      .our-company {
        display: flex;
        align-items: center;
        cursor: pointer;
        .el-image {
          width: 80px;
          height: 80px;
        }
      }
    }
    .contact {
      .content {
        .title {
          font-size: 40px;
          font-weight: bold;
        }
      }
    }
    .bottom {
      text-align: center;
    }
  }
}
@media screen and (max-width: 750px) {
  .body {
    margin-top: 120px;
    .top-swiper {
      :deep(.el-carousel) {
        .el-carousel__container {
          position: relative;
          height: 600px !important;
          .el-image {
            width: 100%;
            height: 600px !important;
          }
          .desc {
            position: absolute;
            width: 70%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 30px !important;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
          }
        }
      }
    }
    .info-content {
      min-height: 600px !important;
      .el-row {
        min-height: 600px !important;
      }
      .img-content {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 600px !important;
        overflow: hidden;
        .full-image {
          width: 100%;
        }
        .pic-content {
          width: 80%;
          height: 60%;
          position: relative;
          .el-image {
            border-radius: 40px;
            overflow: hidden;
          }
          .info-img1 {
            top: 0;
            left: 0;
            width: 80%;
            height: 80%;
            position: absolute;
          }
          .info-img2 {
            right: 0;
            bottom: 0;
            width: 80%;
            height: 80%;
            position: absolute;
          }
        }
      }
    }
  }
}
</style>
