import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import i18n from './language/index'
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import './utils/setRem'
// 动画 animate.css
import animate from "animate.css";
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
Vue.use(animate)
Vue.use(ElementUI,{
  i18n: (key, value) => i18n.t(key, value)
});
Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
